import React from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { navigate } from 'gatsby'
import {
  Button,
  dataLayerEvent,
  hubspotService,
  Input,
  Accept,
  Text,
  Select
} from '@superlogica/design-ui'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { schema } from './Conversion.util'
import { Link } from 'components/helpers'

export type ConversionValues = {
  firstname: string
  email: string
  phone: string
  licenca: string
  cnpj: string
  cargo__temp_: string
  como_voce_ficou_sabendo_do_acelera_: string
  condo__acelera__aceita_do_programa: boolean
}

interface ConversionProps {
  hubspotId: string
  pageName: string
  googleEvent?: string
  disable?: boolean
}

const ProductConversion: React.FC<ConversionProps> = ({
  hubspotId,
  pageName,
  googleEvent,
  disable
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<ConversionValues>({
    resolver: yupResolver(schema)
  })

  const onSubmitForm: SubmitHandler<ConversionValues> = async (data) => {
    const resp = await hubspotService.sendForm({
      formId: hubspotId,
      data,
      pageName
    })

    if (resp.status !== 200) {
      return
    }

    if (!!googleEvent) {
      dataLayerEvent(googleEvent)
    }

    reset()

    return navigate('/obrigado')
  }

  return (
    <form id="form-conversion" noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <Row>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('firstname')}
            id="conversion-firstname"
            label="Seu nome"
            errors={errors}
            disabled={disable}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            className="mb-sm"
            {...register('email')}
            id="conversion-email"
            type="email"
            label="E-mail"
            mask="lowercase"
            errors={errors}
            disabled={disable}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            className="mb-sm"
            {...register('phone')}
            id="conversion-phone"
            type="tel"
            label="Telefone"
            mask="phone"
            maxLength={15}
            errors={errors}
            disabled={disable}
          />
        </Col>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('licenca')}
            id="conversion-license"
            label="Licença"
            errors={errors}
            disabled={disable}
          />
        </Col>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('cnpj')}
            id="conversion-cnpj"
            mask="cnpj"
            maxLength={18}
            label="CNPJ"
            errors={errors}
            disabled={disable}
          />
        </Col>
        <Col xs={12}>
          <Select
            className="mb-sm"
            {...register('cargo__temp_')}
            id="conversion-cargo__temp_"
            label="Qual o seu cargo?"
            errors={errors}
            options={[
              'CEO/Proprietário(a)',
              'Diretor/Gerente',
              'Analista/Assistente',
              'Outro'
            ]}
            disabled={disable}
          />
        </Col>
        <Col xs={12}>
          <Select
            className="mb-sm"
            {...register('como_voce_ficou_sabendo_do_acelera_')}
            id="conversion-como_voce_ficou_sabendo_do_acelera_"
            label="Como ficou sabendo do Acelera?"
            errors={errors}
            options={[
              'E-mail',
              'Linkedin',
              'Redes Sociais',
              'Sites e Blog',
              'Whatsapp'
            ]}
            disabled={disable}
          />
        </Col>
        {!!!disable && (
          <>
            {/* @ts-ignore */}
            <Col xs={12} className="mb-xs">
              <Accept
                id="conversion-accept_terms"
                {...register('condo__acelera__aceita_do_programa')}
                value="1"
                errors={errors}
              >
                Li e aceito os{' '}
                <Button
                  tag={Link}
                  to="https://acelera.superlogica.com/termos-de-uso"
                  color="violet500"
                  appearance="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="termos de uso"
                >
                  termos de uso
                </Button>
                ,{' '}
                <Button
                  tag={Link}
                  to="https://acelera.superlogica.com/regulamento"
                  color="violet500"
                  target="_blank"
                  rel="noopener noreferrer"
                  appearance="link"
                  title="regulamento"
                >
                  regulamento
                </Button>
                ,{' '}
                <Button
                  tag={Link}
                  to="https://sebrae.contatosebraesp.com.br/l/VrLRfdABF1115"
                  color="violet500"
                  appearance="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="termo de consentimento"
                >
                  termo de consentimento
                </Button>
                , e{' '}
                <Button
                  tag={Link}
                  to="https://minio-cpe.sebrae.com.br/documento/politica-privacidade.pdf"
                  color="violet500"
                  appearance="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="tratamento de dados"
                >
                  tratamento de dados
                </Button>
              </Accept>
            </Col>
            <Col xs={12}>
              <Button
                color="warning"
                type="submit"
                weight="semiBold"
                isLoading={isSubmitting}
                fluid={true}
              >
                Fazer sua inscrição
              </Button>
            </Col>
            <Col xs={12}>
              <Text size="label03" marginTop={20}>
                Finalizando o preenchimento da sua inscrição/participação, você
                autorizará o compartilhamento de seus dados com a Superlógica e
                com as empresas parceiras, conforme a Lei Geral de Proteção a
                Dados Pessoais (Lei Nº 13.709, de 14 de agosto de 2018).
              </Text>
            </Col>
          </>
        )}
      </Row>
    </form>
  )
}

export default ProductConversion
